<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="姓名">
          <el-input v-model="searchData.idName" size="small" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="searchData.mobile" size="small" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input v-model="searchData.idCard" size="small" placeholder="请输入身份证号 "></el-input>
        </el-form-item>
        <el-form-item label=" " label-width="50px">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getHitListSingle">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>

    <el-button v-checkbtn="isShowBtn(AUTH_BTN.hit_list_import)" style="margin-bottom:20px" type="primary" size="small" @click="handleUpload"
      >批量撞库查询</el-button
    >
    <!-- <tp-table :tableData="list" :columns="columns" :showPagination="false" /> -->
    <!-- 导入 -->
    <ImportFile ref="importFile" @getList="getHitListSingle"></ImportFile>
    <div style="height:50px"></div>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { getHitListSingleAPI } from './api'
import ImportFile from './import-file.vue'
export default {
  name: 'List',
  mixins: [authBtnMixin],
  components: { ImportFile },
  data() {
    return {
      searchData: {
        idCard: '',
        mobile: '',
        idName: ''
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {},
  activated() {},
  mounted() {},

  methods: {
    async getHitListSingle() {
      let arr = []
      let tip = ''
      const { hitList } = await getHitListSingleAPI(this.searchData)
      hitList.forEach(item => {
        if (item == 1) {
          arr.push('黑名单')
        } else if (item == 2) {
          arr.push('灰名单')
        }
      })

      tip = arr.length > 0 ? '该用户在' + arr.join('、') + '中' : '未查询到结果'
      this.$alert(tip, '查询结果', {
        confirmButtonText: '确定',
        callback: action => {},
        type: 'warning'
      })

      // const [res] = hitList
      // if (!res) {
      //   this.$alert('未查询到结果', '查询结果', {
      //     confirmButtonText: '确定',
      //     callback: action => {},
      //     type: 'warning'
      //   })
      // }
      // if (res == 1) {
      //   this.$alert('该用户在黑名单中', '查询结果', {
      //     confirmButtonText: '确定',
      //     callback: action => {},
      //     type: 'warning'
      //   })
      // }
      // if (res == 2) {
      //   this.$alert('该用户在灰名单中', '查询结果', {
      //     confirmButtonText: '确定',
      //     callback: action => {},
      //     type: 'warning'
      //   })
      // }
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
    },
    handleUpload() {
      this.$refs.importFile.dialogImport = true
      this.$nextTick(() => {
        this.$refs.importFile.clear()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .header-search {
    .el-form {
      .el-form-item {
        margin-right: 35px;
        .el-input {
          width: 250px;
        }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 35%;
          }
        }
      }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 20px;
  }
  .move-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    .item {
      flex: 1;
      margin: 0 50px;
      border-bottom: 1px solid #ccc;
      padding-bottom: 15px;
      padding-right: 20px;
      display: flex;
      justify-content: space-between;
      & > span {
        font-size: 16px;
        &:first-child {
          margin-right: 100px;
        }
      }
      .el-checkbox__inner {
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50%;
        &::after {
          left: 6px;
          height: 11px;
          width: 5px;
        }
      }
    }
  }
  .move-submit {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;
  }
}
</style>
